import Vue from "vue";
import { headroom } from "vue-headroom";
import BootstrapVue from "bootstrap-vue";
import imagesloaded from "imagesloaded";
import AOS from "aos";
import Flickity from "flickity";
import Splitting from "splitting";



///////////////////////////////
// Initialize
///////////////////////////////

// Vue Boostrap
Vue.use(BootstrapVue);

// AOS
AOS.init({
  duration: 800
});

// Splitting
Splitting();

// Flickity
window.Flickity = Flickity;

// Imagesloaded
window.imagesLoaded = imagesloaded;

///////////////////////////////
// Functions
///////////////////////////////



// Page Section Background Image Preloader
imagesloaded(".page-section__background", { background: true }, function() {
  const backgrounds = document.querySelectorAll(".page-section__background");
  backgrounds.forEach(item => {
    item.classList.add("page-section__background--loaded");
  });
});

// Fade out section headers
const header = document.querySelector(".page-header .page-section__background");
if (header) {
  window.addEventListener("scroll", function(e) {
    const offset = $(window).scrollTop();
    if (offset <= header.clientHeight) {
      header.classList.add("scrolling");
      header.style.opacity = 1 - offset / header.clientHeight;
    } else {
      header.classList.remove("scrolling");
    }
  });
}

// DL Dropdowns
const dropdowns = document.querySelector(".dl-menuwrapper");
if (dropdowns) {
  $(".dl-menuwrapper").dlmenu({
    useActiveItemAsBackLabel: true
  });
}

// Navbar
const navbar = document.querySelector("#vue-navbar");
if (navbar) {
  new Vue({
    name: "Navbar",
    el: "#vue-navbar",
    components: {
      headroom
    },
    mounted() {
      const sections = document.querySelectorAll("[data-section]");
      const navbar = document.querySelector("#vue-navbar");
      const windowHeight = window.innerHeight;
      // const navbarObserver = new IntersectionObserver((entries) => {
      //   entries.forEach(entry => {
      //     if (entry.intersectionRatio > 0) {
      //       const sectionColor = entry.target.dataset.section;
      //       const nav = navbar.querySelector('.navbar');
      //       if (sectionColor === 'dark') {
      //         nav.classList.remove('navbar-light');
      //         nav.classList.add('navbar-dark');
      //       } else {
      //         nav.classList.remove('navbar-dark');
      //         nav.classList.add('navbar-light');
      //       }
      //     }
      //   })
      // }, {
      //   rootMargin: `0px 0px -${windowHeight - navbar.clientHeight}px 0px`
      // });
      // sections.forEach((section) => {
      //   navbarObserver.observe(section);
      // });
    }
  });
}





// Accordions
const accordions = document.querySelectorAll(".vue-accordion");
if (accordions) {
  accordions.forEach((el, i) => {
    new Vue({
      el,
      data() {
        return {
          instance: i
        };
      }
    });
  });
}

// Cycle
var progress = $("#progress"),
  slideshow = $(".cycle-slideshow");

slideshow.on("cycle-initialized cycle-before", function(e, opts) {
  progress.stop(true).css("width", 0);
});

slideshow.on("cycle-initialized cycle-after", function(e, opts) {
  if (!slideshow.is(".cycle-paused"))
    progress.animate({ width: "100%" }, opts.timeout, "linear");
});

slideshow.on("cycle-paused", function(e, opts) {
  progress.stop();
});

slideshow.on("cycle-resumed", function(e, opts, timeoutRemaining) {
  progress.animate({ width: "100%" }, timeoutRemaining, "linear");
});

const searchForm = $("#searchform");
if (searchForm) {
  const $submitButton = searchForm.find('#searchsubmit');
  $submitButton.on('click', function(e) {
    event.preventDefault();
    window.location.href = `${origin}/properties?search=${$('[name=search]').val()}`
  })
}

//------------------------------------//
//Select Boxes//
//------------------------------------//
$(".sel").each(function() {
  $(this)
    .children("select")
    .css("display", "none");

  var $current = $(this);

  $(this)
    .find("option")
    .each(function(i) {
      if (i == 0) {
        $current.prepend(
          $("<div>", {
            class: $current.attr("class").replace(/sel/g, "sel__box")
          })
        );

        var placeholder = $(this).text();
        $current.prepend(
          $("<span>", {
            class: $current.attr("class").replace(/sel/g, "sel__placeholder"),
            text: placeholder,
            "data-placeholder": placeholder
          })
        );

        return;
      }

      $current.children("div").append(
        $("<span>", {
          class: $current.attr("class").replace(/sel/g, "sel__box__options"),
          text: $(this).text()
        })
      );
    });
});

// Toggling the `.active` state on the `.sel`.
$(".sel").click(function() {
  $(this).toggleClass("active");
});

// Toggling the `.selected` state on the options.
$(".sel__box__options").click(function() {
  var txt = $(this).text();
  var index = $(this).index();

  $(this)
    .siblings(".sel__box__options")
    .removeClass("selected");
  $(this).addClass("selected");

  var $currentSel = $(this).closest(".sel");
  $currentSel.children(".sel__placeholder").text(txt);
  $currentSel.children("select").prop("selectedIndex", index + 1);
});


//drawer
$(document).ready(function() {
  $(".drawer").drawer();
});

$('.drawer').drawer({
  class: {
    nav: 'drawer-nav',
    toggle: 'drawer-toggle',
    overlay: 'drawer-overlay',
    open: 'drawer-open',
    close: 'drawer-close',
    dropdown: 'drawer-dropdown'
  },
  iscroll: {
    // Configuring the iScroll
    // https://github.com/cubiq/iscroll#configuring-the-iscroll
    mouseWheel: true,
    preventDefault: false
  },
  showOverlay: false
});


//------------------------------------//
//Object Fit IE Fix//
//------------------------------------//
if ( window && window.Modernizr && !Modernizr.objectfit ) {
  $('.image-container').each(function () {
    var $container = $(this),
      imgUrl = $container.find('img').prop('src');
    if (imgUrl) {
      $container
        .css('backgroundImage', 'url(' + imgUrl + ')')
        .addClass('compat-object-fit');
    }
  });
}

//card slider

$('.main-carousel').flickity({
  // options
  cellAlign: 'left',
  contain: true
});


